export default {
  BUTTON_PRIMARY_CLASS: 'btn btn_inverted',
  BUTTON_SECONDARY_CLASS: 'btn',
  GARAGE_TITLE: '',
  PRODUCT_REPEATER_CLASS: 'row product_listing__main',
  FACET_DIALOG_TITLE: 'Filter Options',

  SEARCH_BOX_PLACEHOLDER: 'Search store',
  SEARCH_BOX_CLASS: 'search_form focused',
  SEARCH_BOX_INPUT_CLASS: 'form-control',
};
